$c_primary: #05A633;
$c_primary_gradient: linear-gradient(-90deg, #076221 0%, #05A633 100%);
$c_secondary: #EBA825;
$c_star: #FFDD2C;
$c_body: #5F5F5F;
$c_heading: #1E1E1E;
$c_success: #05A66C;
$c_warning: #E4A910;
$c_danger: #E74C4C;
$c_border: #E6E6E6;
$c_grey: #EBEBEB;
$c_grey_dark: #302B2B;
$c_black: #000000;
$c_white: #ffffff;
$f_body: "Poppins", sans-serif;
$f_Urbanist: "Urbanist", sans-serif;
$f_Proza: "Proza Libre", sans-serif;
$f_Kumbh: "Kumbh Sans", sans-serif;
$f_Eraser: "Eraser";
$transition: all 0.3s ease-in-out 0s;
$shadow1: 0px 0px 17px 4px #E3EBE3;