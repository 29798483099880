@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Proza+Libre:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap');

@font-face {
    font-family: 'Eraser';
    src: url('../fonts/EraserDust.eot');
    src: url('../fonts/EraserDust.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EraserDust.woff2') format('woff2'),
        url('../fonts/EraserDust.woff') format('woff'),
        url('../fonts/EraserDust.ttf') format('truetype'),
        url('../fonts/EraserDust.svg#EraserDust') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eraser';
    src: url('../fonts/EraserRegular.eot');
    src: url('../fonts/EraserRegular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EraserRegular.woff2') format('woff2'),
        url('../fonts/EraserRegular.woff') format('woff'),
        url('../fonts/EraserRegular.ttf') format('truetype'),
        url('../fonts/EraserRegular.svg#EraserRegular') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}