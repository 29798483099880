@mixin content-limit($line) {
    white-space: normal;
    display: -webkit-box !important;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin breakpoint($point) {
    @if $point==desktop {
        @media only screen and (min-width: 1400px) {
            @content;
        }
    }

    @else if $point==laptop {
        @media only screen and (max-width: 1399.98px) {
            @content;
        }
    }

    @else if $point==normal {
        @media only screen and (max-width: 1199.98px) {
            @content;
        }
    }

    @else if $point==ipad {
        @media only screen and (max-width: 991.98px) {
            @content;
        }
    }

    @else if $point==mobile {
        @media only screen and (max-width: 767.98px) {
            @content;
        }
    }

    @else if $point==mobile_s {
        @media only screen and (max-width: 575.98px) {
            @content;
        }
    }

    @else if $point==min_ipad {
        @media only screen and (min-width: 768px) {
            @content;
        }
    }
}